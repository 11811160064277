<template>
  <div class="profile-addresses">
    <!-- <v-container> -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9">
        <v-skeleton-loader
          v-if="loading"
          :type="$vuetify.breakpoint.xs ? 'card' : 'list-item-three-line'"
        />
        <div v-else>
          <ProfileTitle class="mb-3 mb-sm-0" :category="category" />
          <v-list class="rounded-lg" v-if="currentAddresses.length">
            <v-list-item
              v-for="address in currentAddresses"
              :key="address.addressId"
              class="py-5 py-md-0 py-sm-0"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-avatar>
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="address.selected"
                      color="primary"
                    >
                      $radioChecked
                    </v-icon>
                    <v-icon v-else @click="selectAddress(address)"
                      >$radioUnchecked</v-icon
                    >
                  </v-list-item-avatar>
                </template>
                <span v-if="address.selected">
                  {{ $t("profile.addresses.selectedAddress") }}
                </span>
                <span v-else>{{ $t("profile.addresses.selectAddress") }}</span>
              </v-tooltip>
              <!-- :class="{ zebra: index % 2 == 0 }" -->
              <v-row no-gutters align="center">
                <v-col cols="12" sm="3" md="3">
                  <strong>{{ address.addressName }}</strong>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <span>
                    {{ address.address1 }} {{ address.addressNumber }} -
                    {{ address.city }} ({{ address.province }})
                  </span>
                </v-col>
                <v-col
                  cols="12 pt-md-0 pt-sm-0 pt-8"
                  sm="3"
                  md="3"
                  class="d-flex justify-end"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        height="50"
                        width="60"
                        class="mr-2 secondary"
                        :to="'/profile/addresses/manage/' + address.addressId"
                      >
                        <v-icon medium class="grey--text text--darken-2"
                          >$edit</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Modifica indirizzo</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        height="50"
                        color="error"
                        @click="removeAddress(address)"
                      >
                        <v-icon large>$bin</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("profile.addresses.removeAddress") }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <div
            v-else
            class="text-body-1 text-center text-sm-left primary--text font-weight-bold pa-3"
          >
            Nessun indirizzo salvato. Clicca su Aggiungi indirizzo per inserire
            un indirizzo.
          </div>
          <v-row no-gutters justify="end" class="mt-5">
            <v-btn depressed color="primary" large @click="addAddress = true">{{
              $t("addresses.addAddressButton")
            }}</v-btn>
          </v-row>
          <EditAddress
            v-if="addAddress"
            :showBackButton="false"
            :showSecondStepBackButton="true"
            :openInModal="true"
            @cancelAddAddress="addAddress = false"
            @save="saveAddress"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
:deep(.address-title) {
  h1 {
    font-size: 20px !important;
    color: var(--v-primary-base) !important;
  }
}
:deep(.v-skeleton-loader__list-item-three-line),
:deep(.v-skeleton-loader__card) {
  background: transparent !important;
}
:deep(.pac-target-input) {
  background: $white !important;
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import MenuNavigator from "@/components/profile/MenuNavigator";
import ProfileTitle from "@/components/profile/ProfileTitle";
import EditAddress from "@/views/profile/EditAddress.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: {
    MenuNavigator,
    ProfileTitle,
    EditAddress
  },
  mixins: [categoryMixins],
  data() {
    return {
      currentAddresses: [],
      addAddress: false,
      loading: true
    };
  },
  methods: {
    async fetchAddresses() {
      this.loading = true;
      let vm = this;
      // var data = await DeliveryService.getAddressByDeliveryService(6);
      var data = await DeliveryService.getWarehousesList("home");
      vm.currentAddresses = data.addresses;
      this.loading = false;
    },
    async removeAddress(address) {
      if (address.selected) {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t("errors.errorRemovingSelectedAddress")
        });
      } else {
        const res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeAddress")
        });
        if (res) {
          await DeliveryService.removeAddress(address.addressId);
          await this.fetchAddresses();
        }
      }
    },
    async selectAddress(address) {
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });
      if (data) {
        this.fetchAddresses();
      }
    },
    saveAddress() {
      this.addAddress = false;
      this.fetchAddresses();
    }
  },

  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
